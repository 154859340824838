<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/html-self-closing -->
  <div v-if="!connected" class="w-75 mt-4 mx-auto">
    <b-row>
      <b-col cols="6" class="my-auto">
        <div>
          <h2>Kết nối kênh bán hàng Shopee</h2>
          <p class="text-primary">
            Kết nối hệ thống của IFashion và Shopee, giúp đẩy sản phẩm trực tiếp lên Shopee cũng như đồng bộ về tồn kho, đơn hàng.
          </p>
          <p>
            Kênh bán hàng Shopee Shop của bạn chưa được kích hoạt. Vui lòng kích hoạt để bắt đầu sử dụng.
          </p>
          <b-button
            variant="primary"
            class="btn-icon rounded"
            @click="handleConnect"
          >
            {{ $t('Kích hoạt kênh bán hàng') }}
          </b-button>
        </div>
      </b-col>
      <b-col cols="6">
        <img src="/banner_contact_shopee.png" alt="Shopee">
      </b-col>
    </b-row>
  </div>
  <b-card v-else>
    <b-tabs
      content-class="pt-1"
    >
      <SyncProduct
        :list="syncProductList"
        channel="shopee"
      />
      <NotSyncProduct
        :list="notSyncProductList"
        channel="shopee"
      />
    </b-tabs>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BButton,
  BTabs,
  BCard,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Request from '@/plugins/service/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NotSyncProduct from './components/NotSyncProduct.vue'
import SyncProduct from './components/SyncProduct.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTabs,
    BCard,
    NotSyncProduct,
    SyncProduct,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      channelDetail: null,
      connected: false,
      channels: [
        {
          id: 1,
          name: 'Lazada',
          des: 'Connecting Buso and Lazada systems, helping to synchronize inventory and prices, orders between the website system on Buso and your shop on Lazada',
          img: require('@/assets/images/salesChannel/lazada.svg'),
          connected: false,
          link: 'channel/lazada',
          count: 0,
        },
        {
          id: 2,
          name: 'Shopee',
          des: 'Connecting Buso and Shopee systems, helping to synchronize inventory and prices, orders between the website system on Buso and your shop on Shopee',
          img: require('@/assets/images/salesChannel/shopee.svg'),
          connected: false,
          link: 'channel/shopee',
          count: 0,
        },
        {
          id: 3,
          name: 'Tiki',
          des: 'Connecting Buso and Tiki systems, helping to synchronize inventory and prices, orders between the website system on Buso and your shop on Tiki',
          img: require('@/assets/images/salesChannel/tiki.svg'),
          connected: false,
          link: 'channel/tiki',
          count: 0,
        },
        {
          id: 4,
          name: 'Facebook Live',
          des: 'Connecting Buso and Facebook Live systems, helping to synchronize inventory and prices, orders between the website system on Buso and your livestream on Facebook',
          img: require('@/assets/images/salesChannel/facebook.svg'),
          connected: false,
          link: '/setting-fb',
          count: 0,
        },
      ],
      notSyncProductList: [],
      syncProductList: [],
    }
  },
  computed: {
    miniList() {
      const temp = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.channels.length - 1; i++) {
        temp.push(this.channels[i])
      }
      // console.log(temp)
      return temp
    },
  },
  mounted() {
    this.loadSiteInfo()
  },
  methods: {
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            if (res.data.data.token_shopee_v2 !== '' && res.data.data.token_shopee_v2 !== null && res.data.data.token_shopee_v2 !== undefined) {
              this.connected = true
              // Load lấy số lượng sản phẩm đƯợc sync từ shopee
              const respon = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/shopee/get_item_list?site_id=${JSON.parse(localStorage.getItem('siteID')).id}`)
              if (respon.status === 200) {
                if (respon.data.status === true) {
                  this.channels.find(val => val.id === 2).count = respon.data.data.length
                }
              }
            } else if (this.$route.query.shop_id) {
              await this.handlerUpdate(this.$route.query.code, this.$route.query.shop_id)
            }
            this.loadSyncProduct()
            this.loadShopeeProduct(res.data.data.id)
            if (res.data.data.token_live_facebook !== '' && res.data.data.token_live_facebook !== null && res.data.data.token_live_facebook !== undefined) {
              this.channels.find(val => val.id === 4).connected = true
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    handlerChannelDetail(data) {
      this.channelDetail = data
    },
    async handlerSubmit(data) {
      if (data.id === 1) {
        try {
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/lazada/auth_partner?mode=sandbox&path=${window.location.origin}/channel/lazada`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              window.location.href = res.data.data.link
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
      if (data.id === 2) {
        try {
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/shopee/auth_partner?mode=production&path=${window.location.origin}/channel/shopee`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              window.location.href = res.data.data.link
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
      if (data.id === 4) {
        try {
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/facebook/get_oauth?type=1&redirect_uri=${window.location.origin}/channel/facebook-live`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              window.location.href = res.data.data.url
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
    },
    async handleConnect() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/auth_partner?mode=production&path=${window.location.origin}/sales-channel/shopee-v2`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            window.location.href = res.data.data.link
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerUpdate(code, shopID) {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/v2/get_access_token?code=${code}&shop_id=${shopID}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.connected = true
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadShopeeProduct(siteID) {
      this.isShow = true
      try {
        await this.handleCategory(siteID)
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_item_list?site_id=${siteID}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            res.data.data.map(async val => {
              let findIndex = this.syncProductList.findIndex(x => Number(x.shopee_id) === val.item_id)
              findIndex = this.syncProductList.findIndex(x => Number(x.code_e_commerce) === val.item_id)
              if (findIndex < 0) {
                const resDetail = await Request.get(
                  this.$http,
                  `${process.env.VUE_APP_API_URL}/shopee/get_item_detail/${val.item_id}?site_id=${siteID}`,
                )
                if (resDetail.status === 200) {
                  if (resDetail.data.status) {
                    const item = {
                      id: val.item_id,
                      avatar: resDetail.data.data.images[0],
                      name: resDetail.data.data.name,
                      sku: resDetail.data.data.item_sku,
                      category_id: resDetail.data.data.category_id,
                      category_name: this.categories.find(x => x.category_id === resDetail.data.data.category_id).category_name,
                      stock1: resDetail.data.data.stock,
                      price: resDetail.data.data.price,
                      status: resDetail.data.data.status,
                    }
                    this.notSyncProductList.push(item)
                  }
                }
              }
            })
            // console.log(this.notSyncProductList)
          }
        }
        this.isShow = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
        this.isShow = false
      }
    },
    async loadSyncProduct() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}&e_commerce=1`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.syncProductList = await this.getDataTranslation(res.items, true)
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleCategory(siteID) {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_categories?site_id=${siteID}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.categories = res.data.data
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
//   async mounted() {},
//   methods: {},
}
</script>

<style lang="scss" scoped>
// @import '@core/scss/vue/libs/vue-select.scss';
</style>
